import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cadbbb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "store-deposit" }
const _hoisted_2 = { class: "store-head" }
const _hoisted_3 = { class: "store-body" }
const _hoisted_4 = { class: "store-foot" }

import {inject,reactive,ref}  from "vue"
import { useRouter } from 'vue-router';
import HeadInfo from '../components/store.head.info.vue';
import HeadNav from '../components/store.head.nav.vue';
import GoodsDetail from "../../deal/goods/GoodsDetail.vue";
import DealFoot from "../components/store.public.foot.vue";


export default {
  setup(__props) {


const router = useRouter();
const $toast = inject('$toast')
let search = reactive ({ name:"", spec:"" ,texture:""})
const state =reactive({
  screen:  {
    typeId:"",
    name:"",
    texture:"",
    spec:"",
    steelMills:"",
  },
}) 
const GoodsRef = ref()
const currentScreen= (e)=>GoodsRef.value.getSrGoodsList(e)

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HeadInfo),
      _createVNode(HeadNav)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(GoodsDetail)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(DealFoot)
    ])
  ]))
}
}

}