import { useCssVars as _useCssVars } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0486e640"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "head-nav" }
const _hoisted_2 = { class: "nav-main" }
const _hoisted_3 = { class: "nav-box" }

import { reactive, toRefs } from 'vue';
import SteelTypeBox from './store.steel.type.box.vue';
import StoreRoutes from "@/router/modules/StoreRoutes";
import { useRouter } from 'vue-router';

export default {
  props: { type_show: { type: Boolean, default: true, } },
  setup(__props) {

const props = __props

_useCssVars(_ctx => ({
  "5f442e9f": (_unref(sub_color))
}))


const alias = useRouter().currentRoute.value.meta.alias;
const router = useRouter()
const routerList = router.getRoutes()
  .filter(item => item.meta.alias === alias && item.meta.hidden)
  .sort((a, b) => {
    return a.meta.sort - b.meta.sort
  })
const theme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : ""
const subject = reactive({
  sub_title: theme?.title,
  sub_color: theme.color,
  sub_grd_color: theme?.grd_color,
})
const { sub_color } = toRefs(subject);

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(routerList), (item, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: item.path,
            key: index,
            class: _normalizeClass([item.meta.VTitle == '关于我们' ? 'about' : 'route-item'])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.meta.VTitle), 1)
            ]),
            _: 2
          }, 1032, ["to", "class"]))
        }), 128))
      ])
    ])
  ]))
}
}

}