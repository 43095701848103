import axios from "axios";
export var ShareTip =  {
    // shareToWx: function () {  // 分享到微信的二维码
    //     $("#qrcode").qrcode({
    //         text: path,            // 设置二维码内容
    //         render: "table",       // 设置渲染方式
    //         width: 256,            // 设置宽度,默认生成的二维码大小是 256×256
    //         height: 256,           // 设置高度
    //         typeNumber: -1,        // 计算模式
    //         background: "#ffffff", // 背景颜色
    //         foreground: "#000000"  // 前景颜色
    //     });
    // },
    shareToQq: function (content, url, picurl) {   // 分享到腾讯QQ
        var shareqqstring = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodeURIComponent(url) + '&title=' + content + '&desc='+content+'&pics=' + picurl;
        window.open(shareqqstring);
    },
    shareToQqzone: function (title, url, picurl) { // 分享到QQ空间
        var shareqqzonestring = 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + encodeURIComponent(url) + '&title=' + title + '&desc=' + title + '&pics=' + picurl;
        window.open(shareqqzonestring);
    },
    shareToSina: function (title, url, picurl) {   // 分享到新浪微博
        var sharesinastring = 'http://v.t.sina.com.cn/share/share.php?url=' + encodeURIComponent(url) +'&title=' + title +  '&content=utf-8&sourceUrl=' + url + '&pic=' + picurl+'&searchPic=true#_loginLayer_1639641926022';
        window.open(sharesinastring);
    },
    // getImages: function (str,cnt = false) {// 获取文章图片
    //     var images = []
    //     var imgReg = /<img.*?(?:>|\/>)/gi;             // 匹配图片（g表示匹配所有结果i表示区分大小写）
    //     var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;  // 匹配src属性
    //     var arr = str.match(imgReg);
    //     // console.log('所有已成功匹配图片的数组：' + arr);
    //     if(!cnt){
    //         return document.domain + arr[0].match(srcReg)[1]
    //     }
    //     for (var i = 0; i < arr.length; i++) {
    //         var src = arr[i].match(srcReg);            // 获取图片地址 
    //         if (src[1]) {
    //             // console.log('已匹配的图片地址' + (i + 1) + '：' + src[1]);
    //             images.push(document.domain + src[1])
    //         }
    //     }
    //     return images
    // }

}


// var path = window.location.href
// var title = $(".title").html();
// var picurl = ShareTip.getImages('<?=$row["content"]?>')



export  const shareQQ = (data)=>{
    //分享的网址
    let url = data?.url || window.location.href
    // 分享标题
    let title = data?.title || document.getElementsByTagName('title')[0].innerHTML
    // 你的分享图片地址
    let img = data?.img || '@/assets/deal/gxb_deal.png' 
    console.log(img);
    // 你的分享描述
    let summary = data?.summary || '钢,钢材,钢信,钢信通,钢信宝,钢材今天价格,不锈钢, 钢铁在线交易,钢铁线上交易,钢材期货,钢材期货交易,钢材走势,钢铁价格走势,螺纹钢现货价格,不锈钢现货价格' 
    // 你的分享简述
    let desc = data?.desc || ''
    window.open(`http://connect.qq.com/widget/shareqq/index.html?url=${url}&title=${title}&pics=${img}&summary=${summary}&desc=${desc}`)
}
