import axios from "axios"
export function wxShare(share_info) {
  axios({ method: 'get', url:'https://static.gt0571.com/wechat/share',params: {url:window.location.href} }).then(res=>{
    if(res.status){
      open(res.data,share_info)
    }
  })
}

function open(data,share) {
  window.jWeixin.config({
    debug: false,
    appId: data.appid, // 和获取Ticke的必须一样------必填，公众号的唯一标识
    timestamp: data.timestamp, // 必填，生成签名的时间戳
    nonceStr: data.nonceStr, // 必填，生成签名的随机串
    signature: data.signature,// 必填，签名，见附录1
    //需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
    jsApiList: [
      'onMenuShareAppMessage', 
      'onMenuShareTimeline',
      'onMenuShareQQ',
      'onMenuShareWeibo',
      'updateAppMessageShareData',
    ]
  });
  //监听分享动作
  window.jWeixin.ready(function () {
    //分享给好友
    window.jWeixin.updateAppMessageShareData({
      title: share?.title || document.getElementsByTagName('title')[0].innerHTML, // 分享标题
      desc: share?.desc || '钢,钢材,钢信,钢信通,钢信宝,钢材今天价格,不锈钢, 钢铁在线交易,钢铁线上交易,钢材期货,钢材期货交易,钢材走势,钢铁价格走势,螺纹钢现货价格,不锈钢现货价格',	//分享的描述
      link: share?.link || window.location.href, // 分享链接，该链接域名必须与当前企业的可信域名一致
      type: share?.type || 'link', // 分享类型,music、video或link，不填默认为link
      imgUrl: share?.imgUrl || (window.location.origin + require("../assets/gxb_logo_ico.png")) , // 分享图标
      success: function () {
        // 配置成功的操作
      },
    });
  })
}




export default wxShare