import http from "./http"
const baseUrl = 'https://pay.gxb.cn/api'

//品名查询公司
export const $apiNameSearchCom = (data) =>http({ method: 'post', url:baseUrl+'/company/product',data }) 
//议价交易 /news/inquiry
export const $apiInquiryFast = (data) =>http({ method: 'post', url:baseUrl+'/sales/fast',data }) 
//询价列表 /Goods/inquiry/list
export const $apiInquiryList = (data) =>http({ method: 'post', url:baseUrl+'/new/inquiry/list',data })
//用户报价列表
export const $apiUserQuoteList = (data) =>http({ method: 'get', url:baseUrl+'/user/quote/list',params:data }) 
//询价报价 /quote/inquiry
export const $apiInquiryQuote = (data) =>http({ method: 'post', url:baseUrl+'/news/quote/inquiry',data }) 
//议价详情
export const $apiInquiryDetail = (data) =>http({ method: 'get', url:baseUrl+'/inquiry/info',params:data }) 
//询价采购 /news/quick
export const $apiInquiryBuy = (data) =>http({ method: 'post', url:baseUrl+'/publishing/quick',data }) 

//打开关闭询价
export const $apiInquiryHandler = (data) =>http({ method: 'post', url:baseUrl+'/cancel/inquiry',data }) 
//取消报价
export const $apiQuoteCancel = (data) =>http({ method: 'post', url:baseUrl+'/cancel/quote',data })

